var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    {
      staticClass: "error-page fill-height",
      staticStyle: { background: "#122338", width: "450px" },
      attrs: { tag: "section" },
    },
    [
      _c(
        "v-row",
        { staticClass: "text-center", attrs: { justify: "center" } },
        [
          _c(
            "v-col",
            { attrs: { cols: "auto" } },
            [
              _c("h1", { staticClass: "text-h1 font-weight-black" }, [
                _vm._v("404"),
              ]),
              _c("div", { staticClass: "text-h2 mb-5 mt-10" }, [
                _vm._v("Page not found :("),
              ]),
              _c("v-btn", { attrs: { depressed: "", to: "/" } }, [
                _vm._v(" Get me out of here! "),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }